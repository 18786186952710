<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        Actions
        <el-icon><ArrowDown /></el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="showDialog"
            >Nouvelle commande</el-dropdown-item
          >
          <el-dropdown-item command="resendCommand"
            >Ré-envoyer les commandes</el-dropdown-item
          >
          <el-dropdown-item command="reloadPage">Actualiser</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <div class="div">
      <el-tooltip
        class="item"
        effect="dark"
        content="Ajouter commande"
        placement="top"
      >
        <el-button
          type="primary"
          :icon="PlusIcon"
          plain
          @click="dialogVisible = true"
          >Ajouter</el-button
        >
      </el-tooltip>
    </div>
    <div class="div">
      <el-tooltip
        v-if="isQueued"
        class="item"
        effect="dark"
        content="Réenvoyer les commandes en attentes"
        placement="top"
      >
        <el-button
          type="primary"
          :icon="MessageIcon"
          plain
          @click="resendCommand"
          :disable="!resending"
        >
        </el-button>
      </el-tooltip>
      <el-button
        type="primary"
        :icon="Refresh"
        plain
        @click="reloadPage"
        :disable="!resending"
      >
        <el-icon><Refresh /></el-icon
      ></el-button>
    </div> -->

    <el-dialog
      title="Ajouter une commande"
      v-model="dialogVisible"
      append-to-body
      center
    >
      <el-form
        ref="commandForm"
        v-model="form"
        label-width="120px"
        class="demo-ruleForm"
        size="small"
        label-position="top"
      >
        <el-form-item label="Data" prop="desc">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.attributes.data"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >Annuler</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="handleCreate('commandForm')"
            >Confirmer</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ArrowDown } from '@element-plus/icons-vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
export default {
  components: {
    ArrowDown,
  },
  data() {
    return {
      dialogVisible: false,
      dialogFormSearch: false,
      formLabelWidth: '120px',
      form: {
        name: 'custom',
        type: 'custom',
        attributes: {
          data: null,
        },
      },
    }
  },
  computed: {
    ...mapState('devices', ['commands', 'device', 'resending']),
    isQueued() {
      let queued = _.filter(_.get(this.commands, 'content', []), {
        status: 'queued',
      })
      return queued.length > 0
    },
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      this[command]()
    },
    resendCommand() {
      this.$store.commit('device/setResending', true)
      let deviceId = this.device?.id ?? null
      if (!deviceId) {
        ElMessage.error('Device inconnu')
      }
      let payload = {
        deviceId: deviceId,
      }
      this.$store.dispatch('devices/resendCommand', payload)
    },
    showDialog() {
      this.dialogVisible = true
    },
    handleCreate() {
      let payload = {
        deviceId: this.device.id,
        data: { ...this.form },
      }
      this.$store
        .dispatch('devices/addCommandApi', payload)
        .then(() => {
          this.dialogVisible = false
        })
        .then(() => {
          this.form.attributes.data = ' '
        })
    },

    reloadPage() {
      let payload = {
        deviceId: this.device?.id ?? null,
      }
      this.$store.dispatch('devices/getCommandsApi', payload)
    },
  },
}
</script>
