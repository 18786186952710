<template>
  <el-button
    type="primary"
    size="default"
    @click="$router.go(-1)"
    circle
    :icon="backIcon"
  ></el-button>

  <el-tabs tab-position="top" v-model="activeName">
    <el-tab-pane label="Général" name="details"><Details /></el-tab-pane>
    <el-tab-pane label="Commandes" name="command"><Command /></el-tab-pane>
    <el-tab-pane label="Traces" name="trace"><Trace /></el-tab-pane>
    <el-tab-pane label="Endpoint" name="endpoint"><Endpoint /></el-tab-pane>
    <el-tab-pane label="Config" name="config"><Config /></el-tab-pane>
  </el-tabs>
</template>

<script>
// import EndpointDashboard from '../endpoints/EndpointDashboard.vue'
import { Back } from '@element-plus/icons-vue'
import Config from './tabs/Config.vue'
import Command from './tabs/Command.vue'
import Endpoint from './tabs/Endpoint.vue'
import Details from './tabs/Details.vue'
import Trace from './tabs/Trace.vue'
import { mapState } from 'vuex'
export default {
  components: { Details, Config, Command, Trace, Endpoint },
  data() {
    return {
      activeName: 'details',
    }
  },
  computed: {
    ...mapState('devices', ['device', 'selectedTab']),
    backIcon() {
      return Back
    },
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newVal) {
        if (this.device == null) {
          window.location.href = '/devices'
        }
        if (newVal == 'details') {
          let payload = {
            deviceId: this.device?.id ?? null,
          }
          this.$store.dispatch('devices/getDeviceApi', payload)
          this.$store.commit('devices/SET_SETECTED_TAB', 'details')
        }
        if (newVal == 'command') {
          let payload = {
            deviceId: this.device?.id ?? null,
          }
          this.$store.dispatch('devices/getCommandsApi', payload)
          this.$store.commit('devices/SET_SETECTED_TAB', 'command')
        }
        if (newVal == 'trace') {
          this.$store.commit('devices/SET_SETECTED_TAB', 'trace')
        }
        if (newVal == 'endpoint') {
          this.$store.commit('devices/SET_SETECTED_TAB', 'endpoint')
        }
        if (newVal == 'config') {
          this.$store.commit('devices/SET_SETECTED_TAB', 'config')
        }
      },
    },
  },
  mounted() {},
}
</script>

<style></style>
