
<template>
  <div class="demo-image__error">
    <div class="row block ml-5 mt-3">
      <el-descriptions class="mt-0" title="" :column="2">
        <el-descriptions-item label="IMEI">{{
          this.device.uniqueId ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="IMEISV">{{
          endpoint?.imei ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="Adresse IP">{{
          endpoint?.ip_address ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="Nom">{{
          endpoint?.name ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="MSISDN"
          >{{ endpoint?.sim?.msisdn ?? ' -- ' }}
        </el-descriptions-item>
        <el-descriptions-item label="IMSI ">{{
          endpoint?.sim?.imsi ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="ICCID">{{
          endpoint?.sim?.iccid ?? ' -- '
        }}</el-descriptions-item>
        <el-descriptions-item label="Créée le ">{{
          simCreatedAt
        }}</el-descriptions-item>
        <el-descriptions-item label="Modifié le ">{{
          simUpdatedAt
        }}</el-descriptions-item>
        <el-descriptions-item label="Localisation ">
          <el-button plain size="small" @click="getLocation()"
            >Afficher</el-button
          ></el-descriptions-item
        >
      </el-descriptions>
    </div>
    <div class="block ml-5">
      <div style="height: 350px; width: 100%">
        <l-map
          :options="{ zoomControl: true }"
          id="map"
          style="height: 80%; width: 100%"
          class="map"
          ref="map"
          :center="center"
          :zoom="zoom"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
        >
          <l-tile-layer :url="url"> </l-tile-layer>
          <l-marker :lat-lng="markerLatLng" :v-model="markerLatLng">
            <l-popup>{{ text }}</l-popup>
          </l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EmnifyAppToken } from '@/config/config'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import _ from 'lodash'

// import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue3-leaflet'
import 'leaflet/dist/leaflet.css'
import { ref } from 'vue'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      center: [48, 2],
      marker: null,
      motionState: ref('parked'),
      markerLatLng: [0, 0],
      text: '',
      map: null,

      dialogTableDetails: false,
      searchimei: '',
      endpoint: {},
      location: null,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
      devices: 'devices/devices',
      selectedTab: 'devices/selectedTab',
      device: 'devices/device',
      metadata: 'devices/metadata',
      member: 'auth/member',
      org: 'organisations/organisation',
      emnifyToken: 'emnify/authToken',
      // sim: 'emnify/sim',
      sims: 'emnify/sims',
      endpoints: 'emnify/endpoints',
    }),
    simCreatedAt() {
      if (_.get(this.endpoint, 'created', false))
        return dayjs(this.endpoint?.created).format('YYYY-mm-DD HH:mm:ss')
      return ' -- '
    },
    simUpdatedAt() {
      if (_.get(this.endpoint, 'last_updated', false))
        return dayjs(this.endpoint?.last_updated).format('YYYY-mm-DD HH:mm:ss')
      return ' -- '
    },
  },
  props: ['index', 'row'],
  watch: {
    selectedTab(newVal) {
      if (newVal == 'endpoint') {
        this.getEndpoints()
      }
    },
  },
  mounted() {
    this.getEmnifyToken()
  },
  methods: {
    ...mapActions({
      getauthapi: 'emnify/getauthtoken',
      getsims: 'emnify/getSims',
      getEndPoints: 'emnify/getEndpoints',
    }),

    zoomUpdated(zoom) {
      this.zoom = zoom
    },
    centerUpdated(center) {
      this.center = center
    },

    formatDate(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return ''
      }
      return dayjs(date).format('yyyy-mm-DD HH:mm:ss')
    },

    getEndpoints() {
      const emei = this.device.uniqueId
      const lastemei = emei.substring(0, 14)

      let payload = {
        auth_token: this.emnifyToken,
        params: {
          q: 'imei:' + lastemei,
        },
      }
      this.$store
        .dispatch('emnify/getEndpoints', payload)
        .then((response) => {
          if (response.length > 0) this.endpoint = response[0]
          ElMessage({
            message: 'Opération réussie.',
            type: 'success',
          })
        })
        .catch((error) => {
          ElMessage.error(
            _.get(error, 'response.data.error[0]', 'Un problème est survenu')
          )
          console.log('error :', error)
        })
    },
    getLocation() {
      let payload = {
        auth_token: this.emnifyToken,
        endpointId: this.endpoint.id,
      }
      this.$store
        .dispatch('emnify/getLocation', payload)
        .then((response) => {
          if (response.lat && response.lng) {
            this.markerLatLng = [response.lat, response.lng]
          } else {
            ElMessage({
              message: 'Aucune Position',
              type: 'error',
            })
          }

          if (response.length > 0) this.location = response
          ElMessage({
            message: 'Opération réussie.',
            type: 'success',
          })
        })
        .catch((error) => {
          ElMessage.error(
            _.get(error, 'response.data.error[0]', 'Un problème est survenu')
          )
          console.log('error :', error)
        })
    },
    getEmnifyToken() {
      var data = {
        application_token: EmnifyAppToken,
      }
      this.getauthapi(data)
    },
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}
.button-yoda-user:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}
form label {
  font-weight: bold;
}
</style>