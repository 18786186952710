<template>
  <el-row>
    <el-col :span="12" :offset="0">
      <div class="block" style="margin-bottom: 10px;">
        <el-date-picker
          v-model="date"
          class="date"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Date début"
          end-placeholder="Date fin" 
        />
    
      <div class="champs"> 
        <el-select
          v-model="selectedFields"
          multiple
          filterable
          default-first-option
          :reserve-keyword="false"
          placeholder="Choississez les champs"
          aria-required="true"
          style="width: 33%;"
        >
          <el-option
            v-for="item in fields"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :aria-required="true"
          />
        </el-select>
        </div>
        <div class="attributes"> 
        <el-select
          v-model="selectedAttributes"
          multiple
          filterable
          style="width: 33%;"
          default-first-option
          :reserve-keyword="false"
          placeholder="Choississez les attributs"
        >
          <el-option
            v-for="item in attributes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

        <el-button @click="search" class="btn" >
           Chercher    </el-button>
        </div>
        
        </div>
       
      <p>
             <ul>
                <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
             </ul>
      </p>

 
     </el-col>
  
     </el-row>
     <div>
          <TraceTable
       :trace="trace" 
       :selectedFields="selectedFields" 
       :selectedAttributes="selectedAttributes" />
     </div>
</template>

<script>
import TraceTable from './TraceTable.vue'
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import dayjs from 'dayjs'
export default {
  components: { TraceTable },
  el: '#app',
  data() {
    return {
      errors:[],
      fields: [
        {
          value: 'fixTime',
          label: 'Temps',
        },
        {
          value: 'speed',
          label: 'Vitesse',
        },
        {
          value: 'bearing',
          label: 'Angle',
        },
      ],
      attributes: [
        {
          value: 'ignition',
          label: 'Allumage',
        },
        {
          value: 'power',
          label: 'Voltage',
        },
        {
          value: 'distance',
          label: 'distance',
        },
      ],
      canBeDeletedStatus: ['unknown', 'queued'],
      page: 1,
      date: null,
      selectedFields: [],
      selectedAttributes: [],
      trace: [],
    }
  },
  computed: {
    ...mapState('devices', ['commands', 'device']),
    list() {
      return _.get(this.commands, 'content', [])
    },
    totalElements() {
      return _.get(this.commands, 'totalElements', 0)
    },
    size() {
      return _.get(this.commands, 'size', 0)
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      getPositions: 'devices/getPositions',
    }),

    status(status) {
      switch (status) {
        case 'queued':
          return { type: 'primary', label: 'En attente' }
        case 'processed':
          return { type: 'success', label: 'Traité' }
        case 'ignored':
          return { type: 'danger', label: 'Ignoré' }

        default:
          return { type: 'warning', label: 'Inconnue' }
      }
    },
    setPage(page) {
      this.page = page
      this.$store.dispatch('devices/getCommandsApi', {
        deviceId: this.device?.id ?? null,
        params: { page: page - 1 },
      })
    },
    formatedDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    command(row) {
      return _.get(row, 'attributes.data', '--')
    },
    result(row) {
      return _.get(row, 'attributes.result', '--')
    },
    canBeDeleted(row) {
      return this.canBeDeletedStatus.includes(row.status)
    },

    search() {
      let payload = { deviceId: this.device.id }
      if (this.date) {
        payload.startDate = this.date[0]
        payload.endDate = this.date[1]
      }
      let tags = this.selectedFields.join(',').concat(',')
      tags += this.selectedAttributes
        .map((element) => 'attributes.' + element)
        .join(',')

      payload.tags = tags
      this.$store
        .dispatch('devices/getPositions', payload)
        .then((response) => (this.trace = response))
        .catch((error) => {
          console.log('error :', error)
        })
    },
  },
}
</script>

<style>
.block {
  justify-content: center;
  align-items: center;
  width: 200%;
  overflow: hidden;
}
.champs {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 119px;
}
.attributes {
  margin-bottom: 10px;
  margin-right: 119px;
}
.btn {
  margin-left: 11px;
  
}
.date {
  margin-top: 30px;
  
}
</style>
