<template>
  <el-row :gutter="20">
    <el-col :span="12" :offset="6">
      <el-form
        
        ref="form"
        label-position="top"
        label-width="100px"
      >
        <el-form-item label="Attributes :" size="small">
          <el-container direction="vertical">
            <el-row
              :gutter="24"
              v-for="(attribute, index) in attributes"
              :key="index"
              style="margin-bottom: 10px"
            >
              <el-col :span="5">
                <el-input v-model="attribute.key" placeholder="Key" />
              </el-col>
              <el-col :span="16">
                <el-input
                  v-model="attribute.value"
                  type="textarea"
                  placeholder="Value"
                />
              </el-col>
              <el-col :span="3">
                <el-button
                  type="danger"
                  plain
                  :icon="DeleteIcon"
                  @click="deleteAttribute(index)"
                ></el-button>
              </el-col>
            </el-row>
            <el-row justify="end">
              <el-col :span="2">
                <el-button
                  type="primary"
                  plain
                  :icon="PlusIcon"
                  @click="addAttribute"
                ></el-button>
              </el-col>
            </el-row>
          </el-container>
        </el-form-item>
        <el-form-item
          ><el-button type="primary" size="default" plain @click="handleConfirm"
            >Confirmer</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      attributes: [
        {
          key: '',
          value: '',
        },
      ],
    }
  },
  computed: {
    ...mapState('devices', ['config', 'device']),
    mappedAttributes() {
      let attributes = []
      if (_.get(this.config, 'attributes', false)) {
        for (var propName in this.config.attributes) {
          attributes.push({
            key: propName,
            value: this.config.attributes[propName],
          })
        }
      }
      return attributes
    },
  },
  watch: {
    config: {
      handler() {
        this.attributes = this.mappedAttributes
      },
    },
  },
  mounted() {},
  methods: {
    handleConfirm() {
      let payload = {
        deviceId: this.device.id,
        data: {
          id: this.config.id,
        },
      }
      if (this.attributes.length)
        payload.data.attributes = this.payloadAttributes(this.attributes)
      this.$store.dispatch('devices/updateConfig', payload)
    },
    payloadAttributes(attributes) {
      let result = {}
      attributes.forEach((obj) => {
        result[obj.key] = obj.value
      })
      return result
    },
    addAttribute() {
      this.attributes.push({
        key: '',
        value: '',
      })
    },
    deleteAttribute(index) {
      this.attributes.splice(index, 1)
    },
  },
}
</script>

<style></style>
