<template>
  <el-table
    :data="trace.data"
    style="margin-left: 30px"
    
  >
    <el-table-column
      v-if="isSelected('fixTime')"
      :formatter="dateFormatter"
      prop="fixTime"
      label="Temps"
      width="180"
    />
    <el-table-column
      v-if="isSelected('speed')"
      prop="speed"
      label="Vitesse"
      width="180"
    />
    <el-table-column
      v-if="isSelected('bearing')"
      prop="bearing"
      label="Angle"
      width="180"
    />

    <el-table-column
      v-if="isSelected('ignition')"
      prop="attributes.ignition"
      label="Allumage"
      width="180"
    />
    <el-table-column
      v-if="isSelected('power')"
      prop="attributes.power"
      label="Voltage"
      width="180"
    />
    <el-table-column
      v-if="isSelected('distance')"
      prop="attributes.distance"
      label="Distance"
      width="180"
    />
  </el-table>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
import dayjs from 'dayjs'
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    trace: {
      type: Array,
      required: true,
    },
    selectedFields: {
      type: Array,
      required: true,
    },
    selectedAttributes: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      res: [],
      searchorg: '',
      search: '',
      dayjs: dayjs,
    }
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      devices: 'devices/devices',
      eminToken: 'emnify/authToken',
      metadata: 'devices/metadata',
      member: 'auth/member',
      isAppUser: 'auth/isAppUser',
    }),
    ...mapState({
      loading: 'devices/loading',
    }),
    ...mapFields('devices', ['filter']),
    ...mapFields('devices', ['devicesFilter']),
  },
  mounted() { },
  methods: {
    ...mapActions({
      setDevice: 'devices/setDevice',
      deleteDevice: 'devices/deleteDevice',
      getDevices: 'devices/getDevice',
      getEndpointByID: 'emnify/getEndpointByID',
    }),
    formatRetailer(retailer) {
      if (retailer) return retailer.name
    },

    handleSetting(row) {
      this.$store.commit('devices/SET_DEVICE', row)
      this.$router.push({
        name: 'device-settings',
        params: {},
      })
    },
    isSelected(column) {
      if (this.selectedFields.find((item) => item == column)) return true
      if (this.selectedAttributes.find((item) => item == column)) return true
      return false
    },
    dateFormatter(row, column, cellValue) {
      return moment(String(cellValue)).format('MM/DD/YYYY hh:mm')
    },
  },

  filterConnexion(value, row) {
    return row.status === value
  },
  filterEtat(value, row) {
    return row.motionState === value
  },
  handleFilter() {
    this.filter = {
      name: null,
      organizationId: null,
      retailerId: null,
      q: null,
      make: null,
      model: null,
      status: null,
      motionState: null,
      page: 0,
    }
    let payload = {
      params: {},
    }
    this.devicesFilter = false
    this.$store.dispatch('devices/getDevice', payload)
  },
}
</script>

<style>
.button-yoda:hover {
  background-color: #ffef9f;
  border: #ff9b2e;
  color: #ff9b2e;
}
.button-yoda-filter:hover {
  background-color: #c19ee0;
  border: white;
  color: white;
}
form label {
  font-weight: bold;
}
</style>
